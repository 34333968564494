import { Component, Vue } from "vue-property-decorator";

import { SideNav } from "@/layout";
import { HomeworkModel } from "@/models";
import { Lightbox } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { Common } from "../Common";

export class QuizForm {
  shid = '';
  name = '';
  student = '';
  city = '';
  school = '';
  class = '';
  grade = '';
  unit = '';
  level = '';
  question = [];
  num = 0;
  currentQuestion = 0;
  score = 0;
  date = '';
  completeDate = '';
  token = window.localStorage.getItem('adminToken') as string;
}

type response = { [key: string]: unknown };

@Component<AdminHomeworkVerifyInfoController>({
  components: {
    SideNav, Lightbox
  }
})
export default class AdminHomeworkVerifyInfoController extends Vue {
  /*定義data 變數 */
  private doubleClick = false;
  private quizForm = new QuizForm();
  private errorMsg = "";
  private openLB = false;
  private errNo = 0;
  private token = window.localStorage.getItem('adminToken') as string;

  private errorMsgMap: { [key: string]: string } = {
    score: '',
  };

  public async created() {
    const item: { [key: string]: string } = {
      shid: this.$route.query.shid as string,
      token: this.token,
    }
    const editData = await HomeworkModel.getResult(item) as response;
    this.assignDataToForm(editData);
  }

  private assignDataToForm(data: response) {
    Common.checkToken(data.ERR_CODE as number);

    const item = data.info as {
      shid: string;
      name: string;
      student: string;
      city: string;
      school: string;
      class: string;
      grade: string;
      unit: string;
      level: string;
      date: string;
      completeDate: string;
      question: [];
      num: number;
      currentQuestion: number;
      score: number;
    };
    this.quizForm.shid = item.shid;
    this.quizForm.name = item.name;
    this.quizForm.student = item.student;
    this.quizForm.city = item.city;
    this.quizForm.school = item.school;
    this.quizForm.class = item.class;
    this.quizForm.grade = item.grade;
    this.quizForm.unit = item.unit;
    this.quizForm.level = item.level;
    this.quizForm.date = item.date;
    this.quizForm.completeDate = item.completeDate;
    this.quizForm.question = item.question;
    this.quizForm.num = item.num;
    this.quizForm.currentQuestion = item.currentQuestion;
    this.quizForm.score = item.score as number;
  }

  private async validateAndSubmit() {
    if (typeof (this.quizForm.score) !== 'number') {
      this.errorMsgMap = { //先清空error message
        score: '',
      };
      this.errorMsgMap['score'] = '請填入數字'; //放入error message
      return;
    }

    if (!this.doubleClick) {
      this.doubleClick = true;

      const item: { token: string, shid: string, score: number, teacherComment: response } = {
        token: this.token,
        shid: this.quizForm.shid,
        score: this.quizForm.score,
        teacherComment: {},
      }

      for (const [key, option] of Object.entries(this.quizForm.question)) {
        item.teacherComment[key] = {
          hqid: option['hqid'],
          comment: option['comment'],  
        }
      }

      this.errNo = await HomeworkModel.verify(item);
      Common.checkToken(this.errNo);
      this.openLB = true;
      this.doubleClick = false;
      this.errorMsg = ErrorMessage[this.errNo];
    }
  }

  private closeLB() {
    this.openLB = false;
    if (this.errNo === ErrorCode.Success) {
      this.$router.push("/admin/homework/verify");
    }
  }
}
